<template>
 <div style='width: 100%; height: 100%; position: relative;'>
   
     <div class='transition' :style='getControlStyle()'>
    	<!-- CONTROLS -->
    	    
		    <div :style="getAppStyle()+'float: top; width: 100%; vertical-align: middle;'">
		        <div style='vertical-align: middle; text-align: center; font-size: 14pt; font-weight: bold;width: 100%;'>SPECIAL EXPORTS 1.0</div>
		        <div style='vertical-align: middle; text-align: center; font-size: 11pt; font-weight: bold;width: 100%;'>CONTROLS</div>
		        <br/>
		        <br/>
			    <span class='SVcontrolLabel'>Channel/Station:</span><br/>
				<GSelect style='width: 92%' :options="stations" v-model="selectedStation" :multiple="false" @input="getInventory">  
						    	 
				</GSelect>

			    <br/>
			    <div>
			    <div style='display: inline-flex; width: 45%;'>
			    <InputDatePick  :auto="false" 
                            @change="dateChanged" v-model="fromDate"/>  
                            </div>
                            -
                <div style='display: inline-flex; width: 45%;'>
                <InputDatePick  :auto="false" 
                            @change="dateChanged" v-model="untilDate"/>     
                 </div>           

				</div>
		        <br/>
			    
		    	<br/>
		    	<span class='SVcontrolLabel'>Show Controls: </span>
		    	&nbsp;
		    	<app-switch v-model="controlsRight" :checked="controlsRight"/>
		    	<br/>
				<br/>
				<button v-for="(r,ri) in reports" :key="'br'+ri" @click='runReport(r)' :title="r.info" class='button myButton'>{{r.name}}</button>
		    	
		    	
		    </div> <!-- CONTROLS -->
    </div>
    
    <!-- MAIN -->
	<div class='transition' style='float: left; width: 77%;'> 
	    <!-- HEADER -->
	    <div style='float: top; width: 100%; height: 28pt; overflow-y: scroll;'>
		
	  	</div>
	    <!-- HEADER -->
	    
	    <!-- TABLE -->
	    <div style='width: calc(100vH); height: calc(100vh - 120px); '>
	        <div v-if="data && data.id">
			File: {{ data.name}} <br/>
			Type: {{ data.fileTypeValue}} <br/>
			Size: {{ formatNumber(data.size/1024)}}kB<br/>
			<button :title="'Download '+data.name" v-if="data && data.fileTypeValue!=='PDF'" type="button" class="button myButton"
			                       :style="getAppStyle()" 
			                       key="PDF_FLD_REC2"   @click.prevent="downloadUnzip(data)">Download <mdicon :width="20" name="download" /></button>
	    	    
	    	
	    	</div>	

    	</div>  <!-- TABLE -->
    </div>  <!-- MAIN -->
	<PDFViewer name='pdfView' ref='pdfView' />
	<ProgressBar v-if="showProgressBar" :generalInfo=pbInfo :action=pbAction @action=action></ProgressBar>
 </div>
</template>

<script>
import {HTTP, fwAPI, invAPI, reportAPI, setReload, myLocale, formatNumber} from '@/variables.js';
import { getAppStyle, setDarkMode, initAppMode, getBGStyle2 } from '@/AppStyle.js';
import InputTime from '@/components/inputElements/InputTime2';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import InputTimezone from '@/components/inputElements/InputTimezone';
import GridBreakView from '@/components/GridBreakView';
import InputDatePick from '@/components/inputElements/InputDatePick3';
import GSelect from '@/components/misc/GSelect'
import vSelect from 'vue-select'
import PDFViewer from '@/components/PDFViewer';
import ProgressBar from '@/components/ProgressBar';
import 'vue-select/dist/vue-select.css';
var momentTZ = require('moment-timezone')
var timers = [];

export default {
  name: 'GFW Schedule View',
  components : {
   'app-switch': Switch, InputDatePick, GSelect, ProgressBar, PDFViewer
  },
  data () {
    return {
      stationId: 0,
      station: {},
      chartType: 'bar',
      chartData: [],
      chart_config: "",
      selectedStation: {},
      currency: {},
      stations: [],
      data: [],
	  reports: [],
      fromDate: {},
      untilDate: {},
      loadingActive: false,
      controlsRight: false,
	  showProgressBar: false,
	  pbAction: "",
      pbInfo: "",
      pbReport: null,
	  getAppStyle, formatNumber
    }
  },    
  methods: {
    myLocale() { return myLocale();},
    getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 22%; height: calc(100vh - 120px); padding: 2ex;"
    	}
    	return "float: left; width: 22%; height: calc(100vh - 120px); padding: 2ex;"
    },
	action( what, myId, api)
	{
		this[what](myId, api )
	},
    getStations() {
        let that = this;
		this.startLoader()
    	let stationLoad = new Promise(function (resolve, reject) {
            HTTP.post( reportAPI+"/getSpecialReports/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId)
        		 .then( response => 
                 {
                 	var allStations = response.data.stations;
					that.stations = allStations.map(a => {return {id: a.id, label: a.name, visible: true}});
                    that.reports = response.data.reports;
                    that.selectedStation =  that.stations[0];
					that.stopLoader();
                    resolve (that.stationId);                    
                 }).catch(e => {
					    that.stopLoader();
                        this.$toast.error("loading data for media/stations: " + e.response.data, 'Error', { position: "topRight" });
                        reject ();
                    });
	      });
    },
	runReport( report) 
	{
		this.pbAction = "runReportInternal";
    	this.pbInfo = "Run "+ report.info;
    	this.pbReport=report;
  	    this.showProgressBar = true;
	},
	// record SpecialReportRequest ( String from, String to, Long mediaId, String classname) {} 
    runReportInternal(  myId, api) {
        let that = this;
		let from = momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0];
		let to = momentTZ(this.untilDate).utcOffset(0, true).format().split('T')[0];
        let req = { from: from, to: to, mediaId: this.selectedStation.id, classname: this.pbReport.classname};
		//alert( JSON.stringify( req));
    	let dataLoad = new Promise(function (resolve, reject) {
            api.post( reportAPI+"/runSpecialReport/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+myId, req)

        		 .then( response => 
                 {
                 	
                    that.data = response.data;
					that.showProgressBar = false;
                    resolve (true);                    
                 }).catch(e => {
					    that.showProgressBar = false;
                        that.$toast.error("loading data for report: " + e.response.data, 'Error', { position: "topRight" });
                        reject();
                    });
	      });
    },
	downloadUnzip( data)
    {
		
    	this.$refs.pdfView.download(data.data, data.name);
    },
    startLoader()
    {
    	if ( !this.loadingActive)
      	{
	      	this.loadingActive = true;
	      	this.loader = this.$loading.show({
	                    // Optional parameters
	                    container: this.$refs.formContainer,
	                    canCancel: true,
	                    programmatic: false,
	                    onCancel: this.onCancel,
	                    color: '#000000',
					    loader: 'dots',
					    width: 64,
					    height: 64,
					    active: true,
					    backgroundColor: '#ffffff',
					    opacity: 0.5,
					    zIndex: 999,
	                });
	    }
    },
    stopLoader()
	{
		clearInterval( timers.pop());
		this.loadingActive = false;
		this.loader.hide();
	}
  },
  created() {
  	let today = new Date();
	this.fromDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
	this.untilDate = new Date(today.getFullYear(), 11, 31);
  	this.getStations();
  },
  watch: {
  	fromDate: function() { this.getData() },
  	untilDate: function() { this.getData() },
  	selectedStation: function() { this.getData() }
  },
  updated() {
  		//console.log("update...");
	  this.$nextTick(function () {
		    // Code that will run only after the
		    // entire view has been re-rendered
		    //console.log("stopping loader");
		    if (this.loadingActive)
		    {
		        timers.push( setTimeout( this.stopLoader, 100));
		    }
		  })
 	}
}
</script>
<style>

 .transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
  
.transitionFast {
 	transition: all .4s;
   -webkit-transition: all .4s;
 }
.header { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt; 
  font-weight: bold; 
  width: 100% !important; 
  height: 28pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  background-color: #eef; 
  }
.headerDay { 
  font-size: 12pt; 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; 
  height: 28pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc; 
  background-color: #eef; 
 }
.adMain { 
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute; 
  z-Index: 17; 
  background-color: #fff; 
  margin-left: 4pt; 
  margin-right: 4pt; 
  color: #666; 
  width: 95%; 

    opacity:1;
    transition: all .5s;
   -webkit-transition: all .5s;
}

.gridMain { position: absolute; z-Index: 2; text-align: center; width: 99% ;border: 1pt solid #aaa; opacity:1;
    transition: all 1s;
   -webkit-transition: all 1s; }
   
.SVcontrolLabel {
	font-weight: bold;
	padding-top: 9pt;
}
</style>
